export const FILTER_TYPE_FETCH = {
	BUILDING: 2, // GEOGRAPHY
	CREATER: 6,
	PROCESS: 8,
	SUBAPPLICATION: 20,
	SEGMENT: 21,
	CATEGORY: 22,
	PRODUCT_FAMILY: 23,
	APPLICATION: 27,
	PRODUCT: 24,
	CLUSTER: 25,
	WORKFORCE_TAG: 26,
	// creator: 'REFERENCE_FILES',
	FORMS_LIST: 30,
	FIELDS_LIST: 29,
	ASSET_TAG1: 31,
	ASSET_TAG2: 32,
	ASSET_TAG3: 33,
	ASSET_TAG_TYPE1: 34,
	ASSET_TAG_TYPE2: 35,
	ASSET_TAG_TYPE3: 36,
}

export const FILTER_TYPE_LIST = {
	CLUSTER: 2,
	BUILDING: 3,
	RESOURCE: 4, //creator
	SEGMENT: 5,
	WORKFORCE: 6,
	PRODUCT_CATEGORY: 9,
	PRODUCT_FAMILY: 10,
	PRODUCT: 11,
	DATE_TYPE: 13,
	STATUS: 14,
	SUB_STATUS: 15,
	ROLE: 25,
	START_DATE: 17,
	END_DATE: 18,
	FORMS: 19,
	FIELDS: 20,
	PROCESS: 21,
	TIMELINE: 22,
	ASSET_TAG1: 50,
	ASSET_TAG2: 51,
	ASSET_TAG3: 52,
	ASSET_TAG_TYPE1: 54,
	ASSET_TAG_TYPE2: 55,
	ASSET_TAG_TYPE3: 56,
}

export const REPORT_TYPE = {
	FEASIBLITY: 15,
	MEETINGS: 17,
	MOM: 18,
}
