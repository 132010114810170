import {
	Box,
	HStack,
	Icon,
	Text,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	Divider,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdAdd, MdArrowBack } from 'react-icons/md'
import { useAuthContext } from '../../../context/auth.context'
import { useLanguageContext } from '../../../context/locale.context'
// import {
//   dataLayerTagManager,
//   GA_CATEGORY_DIALOG_BOX,
//   sectionDetailedTrack,
// } from '../../repository/repo.utils'
import AddExport from './add-export/add-export'
import SavedExport from './saved-export/saved-export'
const ExportsPan = ({
	cbAddExportToList,
	cbSaveExport,
	cbRemoveSavedExport,
	savedExportList,
	isLoading,
}) => {
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	// TODO: Don't use boolean make it enum based so we can have way to extends
	const [isAddExportView, setIsAddExportView] = useState(false)
	const [isNotValidForm, setIsNotValidForm] = useState(false)
	const [errMsg, setErrMsg] = useState('')

	useEffect(() => {
		if (!!isLoading) {
			setIsAddExportView(false)
		}
	}, [isLoading])
	return (
		<Box py={3}>
			<Modal
				isOpen={isNotValidForm}
				onClose={() => {
					setIsNotValidForm(false)
				}}
			>
				<ModalOverlay />
				<ModalContent borderRadius='md'>
					<ModalHeader textAlign='left'>Error</ModalHeader>
					<ModalCloseButton />
					<Divider />
					<ModalBody>
						<Text fontSize='medium' fontWeight='400'>
							{errMsg}
						</Text>
					</ModalBody>
					<ModalFooter>
						<HStack w='full' justifyContent='flex-end' alignItems='center'>
							<Button
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								onClick={() => {
									// sectionDetailedTrack({
									//   category: GA_CATEGORY_DIALOG_BOX,
									//   action: 'Export Panel',
									//   label: 'Okay',
									// })
									setIsNotValidForm(false)
								}}
								borderRadius='md'
							>
								{locale['Okay']}
							</Button>
						</HStack>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{isAddExportView ? (
				<Button
					w='100%'
					leftIcon={<Icon as={MdArrowBack} w={8} h={8} />}
					variant='filled'
					bg='secondary'
					color={localStorage.getItem('color')}
					fontWeight='400'
					borderRadius='0'
					textTransform='capitalize'
					onClick={() => {
						// sectionDetailedTrack({
						//   category: GA_CATEGORY_DIALOG_BOX,
						//   action: 'Export Panel',
						//   label: 'Save Filters',
						// })
						setIsAddExportView(false)
					}}
				>
					{locale['Saved Filters']}
				</Button>
			) : (
				<Button
					w='100%'
					leftIcon={<Icon as={MdAdd} w={8} h={8} />}
					variant='filled'
					bg={localStorage.getItem('color')}
					color='white'
					borderRadius={6}
					fontWeight='400'
					boxShadow='md'
					textTransform='capitalize'
					onClick={() => {
						// sectionDetailedTrack({
						//   category: GA_CATEGORY_DIALOG_BOX,
						//   action: 'Export Panel',
						//   label: 'Add New Filters',
						// })
						// dataLayerTagManager('button_click', {
						//   viewName: 'Export',
						//   buttonName: 'Add New Filters',
						// })
						setIsAddExportView(true)
					}}
				>
					{locale['Add New Filters']}
				</Button>
			)}
			{/* {isNotValidForm && isAddExportView && (

        <Text p={'2'} color={'red.400'}>
          {errMsg}
        </Text>
      )} */}
			<Box pt='10px' h={'calc(100vh - 160px)'} overflowY='scroll'>
				{isAddExportView ? (
					<AddExport
						deskAssetId={authData.asset_id}
						cbAddExport={cbAddExportToList}
						cbSaveExport={cbSaveExport}
						setErrMsg={setErrMsg}
						setIsNotValidForm={setIsNotValidForm}
						isNotValidForm={isNotValidForm}
						errMsg={errMsg}
					/>
				) : (
					<SavedExport
						cbAddExport={cbAddExportToList}
						cbRemoveExport={cbRemoveSavedExport}
						savedExportList={savedExportList}
						isLoading={isLoading}
					/>
				)}
			</Box>
		</Box>
	)
}

export default ExportsPan
