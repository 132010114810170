import React, { useState } from 'react'
import {
	Box,
	Button,
	Icon,
	Flex,
	Spacer,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Text,
	Skeleton,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Tooltip,
} from '@chakra-ui/react'
import { MdDelete, MdArrowDropDown } from 'react-icons/md'
import SavedListItem from './saved-list'
// import {
//   GA_CATEGORY_EXPORT,
//   GA_CATEGORY_DIALOG_BOX,
//   sectionDetailedTrack,
//   dataLayerTagManager,
// } from '../../../repository/repo.utils'
import { useLanguageContext } from '../../../../context/locale.context'
const SavedExportList = ({
	savedExportList,
	cbAddExport,
	cbRemoveExport,
	isLoading,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [savedExport, setSavedExport] = useState('')
	const [selectedIndex, setSelectedIndex] = useState(null)
	const cancelRef = React.useRef()
	const {
		state: { locale },
	} = useLanguageContext()

	const deleteSavedExport = (e, savedData) => {
		e.stopPropagation()
		setSavedExport(savedData)
		setIsOpen(true)
	}
	const coinfirmDelete = () => {
		setIsOpen(false)
		setSelectedIndex(null)
		cbRemoveExport(savedExport)
	}

	return (
		<>
			<Accordion
				allowToggle={true}
				w='full'
				index={selectedIndex}
				onChange={i => setSelectedIndex(i)}
			>
				{!!isLoading
					? [1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => {
							return (
								<Skeleton
									boxShadow='md'
									borderRadius='md'
									key={i}
									p={2}
									w='100%'
									h='50px'
									mb={1}
								/>
							)
					  })
					: savedExportList.map((item, index) => {
							return (
								<AccordionItem
									key={Math.random() * Date.now()}
									border='none'
									boxShadow='md'
									borderRadius='md'
									mb={2}
								>
									{({ isExpanded }) => (
										<>
											<AccordionButton
												_expanded={{
													bg: 'secondary',
													color: 'brand.900',
													borderRadius: 'md',
												}}
												_focus={{
													border: 'none',
												}}
											>
												<Box
													flex='1'
													textAlign='left'
													w='full'
													overflowX='hidden'
												>
													<Text
														size='sm'
														fontWeight='500'
														textTransform='capitalize'
														whiteSpace={isExpanded ? 'none' : 'nowrap'}
														overflow='hidden'
														textOverflow='ellipsis'
														w='98%'
													>
														{item.report_filter_name}
													</Text>
												</Box>
												{isExpanded && (
													<Box
														pr='10px'
														onClick={e => {
															// sectionDetailedTrack({
															//   category: GA_CATEGORY_EXPORT,
															//   action: 'Export Form',
															//   label: 'Delete Saved Export',
															// })
															// dataLayerTagManager('button_click', {
															//   viewName: 'Export',
															//   buttonName: 'Delete Saved Filter',
															// })
															deleteSavedExport(e, item)
														}}
													>
														<Icon as={MdDelete} />
													</Box>
												)}
												<AccordionIcon as={MdArrowDropDown} />
											</AccordionButton>
											<AccordionPanel>
												<SavedListItem
													savedFieldData={item}
													cbOnApply={cbAddExport}
												/>
											</AccordionPanel>
										</>
									)}
								</AccordionItem>
							)
					  })}
			</Accordion>
			{/* {!!isOpen && */}
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={() => setIsOpen(false)}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{locale['Delete']} {savedExport.report_filter_name}
						</AlertDialogHeader>

						<AlertDialogBody>
							{locale["Are you sure? You can't undo this action afterwards."]}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								ref={cancelRef}
								onClick={() => {
									// sectionDetailedTrack({
									//   category: GA_CATEGORY_DIALOG_BOX,
									//   action: 'Saved Export',
									//   label: 'Cancel',
									// })
									setIsOpen(false)
								}}
							>
								{locale['Cancel']}
							</Button>
							<Button
								colorScheme='red'
								onClick={() => {
									// sectionDetailedTrack({
									//   category: GA_CATEGORY_DIALOG_BOX,
									//   action: 'Saved Export',
									//   label: 'Delete',
									// })
									coinfirmDelete()
								}}
								ml={3}
							>
								{locale['Delete']}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			{/* } */}
		</>
	)
}
export default SavedExportList
