/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  HStack,
  Divider,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import ExportWorkspace from './export'
import ExportList from './export-list'
import { buildAddExportParams, buildSaveFilterParams } from './export.build'
import Card from '../../components/card'
import { useAuthContext } from '../../context/auth.context'
import {
  useAddExportReport,
  useGetSavedReportFilter,
  useLoadExportList,
  useRemoveExportReport,
  useSaveExportFilter,
} from '../../hooks/export.hooks'
import {
  GA_CATEGORY_DIALOG_BOX,
  sectionDetailedTrack,
} from '../repository/repo.utils'

const ExportReportPanel = () => {
  const {
    state: { authData },
  } = useAuthContext()
  const { mutate: addReportFiltersMutate } = useAddExportReport()
  const { mutate: saveExportFilterMutate } = useSaveExportFilter()

  const { mutate: fetchExportListMutate, isLoading } = useLoadExportList()
  const { mutate: removeSavedExportMutate } = useRemoveExportReport()
  const {
    mutate: fetchSavedExportListMutate,
    isLoading: isListLoading,
  } = useGetSavedReportFilter()
  const [exportList, setExportList] = useState([])
  const [savedExportList, setSavedExportList] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    getExportData()
    getSavedExportData()
  }, [])
  const getSavedExportData = useCallback(() => {
    let params = {
      target_asset_id: authData.asset_id,
      page_start: 0,
      page_limit: 100,
    }
    fetchSavedExportListMutate(params, {
      onSuccess: async res => {
        const {
          data: { status, response },
        } = res
        if (status === 200) {
          setSavedExportList(response)
        }
      },
      onError: async err => {
        console.log(err)
      },
    })
  })

  const getExportData = useCallback(() => {
    let params = {
      flag_report_type: 9,
      page_start: 0,
      page_limit: 500,
    }
    fetchExportListMutate(params, {
      onSuccess: async res => {
        const {
          data: { status, response },
        } = res
        if (status === 200) {
          setExportList(response)
        }
      },
      onError: async err => {
        console.log(err)
      },
    })
  })
  const addExportToList = useCallback(data => {
    const params = buildAddExportParams(data)

    addReportFiltersMutate(params, {
      onSuccess: async res => {
        const {
          data: { status },
        } = res
        if (status === 200) {
          onOpen()
        }
      },
    })
  })
  const removeSavedExport = useCallback(report => {
    const params = {
      target_asset_id: authData.asset_id,
      report_filter_id: report.report_filter_id,
    }
    removeSavedExportMutate(params, {
      onSuccess: async res => {
        const {
          data: { status },
        } = res
        if (status === 200) {
          getSavedExportData()
        }
      },
    })
  })
  const saveExport = useCallback(data => {
    const params = buildSaveFilterParams(data)
    saveExportFilterMutate(params, {
      onSuccess: async res => {
        const {
          data: { status },
        } = res
        if (status === 200) {
          getSavedExportData()
        }
      },
    })
  })
  return (
    <>
      <Card
        minH="calc(100vh - 92px)"
        maxH="calc(100vh - 92px)"
        h="calc(100vh - 92px)"
        w={{ base: '250', md: '360px', lg: '400px' }}
      >
        <ExportWorkspace
          cbAddExportToList={addExportToList}
          cbSaveExport={saveExport}
          cbRemoveSavedExport={removeSavedExport}
          isLoading={isListLoading}
          savedExportList={savedExportList}
        />
      </Card>
      <Box
        flex={1}
        minH="calc(100vh - 92px)"
        maxH="calc(100vh - 92px)"
        h="calc(100vh - 92px)"
      >
        <ExportList exportList={exportList} isLoading={isLoading} />
      </Box>
      {/** TODO: Update this modal as per the design
       * @sahil please prepare design for modal and share
       * */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="sm">
          <ModalHeader textAlign="center">
            <Text fontWeight="400">Report created successfully</Text>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalFooter>
            <HStack w="full" justifyContent="center" alignItems="center">
              <Button
                colorScheme={localStorage.getItem('color')}
                bg={localStorage.getItem('color')}
                mr={3}
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_DIALOG_BOX,
                    action: 'Export Report Panel',
                    label: 'Export Data',
                  })
                  onClose()
                  getExportData()
                }}
                minW="180px"
                borderRadius="sm"
              >
                Okay
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ExportReportPanel
