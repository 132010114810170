import React, { useState } from 'react'
import { Box, SimpleGrid, Button, HStack, Text, Select } from '@chakra-ui/react'
import 'react-datepicker/dist/react-datepicker.css'
import '../export.scss'
import DatePicker from 'react-datepicker'
import {
  addMonths,
  subMonths,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  addQuarters,
  subQuarters,
} from 'date-fns'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../../../repository/repo.utils'
import { useLanguageContext } from '../../../../context/locale.context'

const SavedList = ({ savedFieldData, cbOnApply }) => {
  const {
    state: { locale },
  } = useLanguageContext()
  const [selectedTimeline, setSelectedTimeline] = useState(
    savedFieldData.report_timescale_id
  )
  const [startDateTime, setStartDateTime] = useState(
    new Date(savedFieldData.report_start_datetime)
  )
  const [endDateTime, setEndDateTime] = useState(
    new Date(savedFieldData.report_end_datetime)
  )

  const timeScaleList = [
    { value: 0, label: locale['Custom'] },
    { value: 1, label: locale['Day'] },
    { value: 2, label: locale['Week'] },
    { value: 3, label: locale['Current Month'] },
    { value: 4, label: locale['Last Month'] },
    { value: 5, label: locale['Next Month'] },
    { value: 6, label: locale['Current Quarter'] },
    { value: 7, label: locale['Last Quarter'] },
    { value: 8, label: locale['Next Quarter'] },
    { value: 9, label: locale['Previous 6 Months'] },
    { value: 10, label: locale['Next 6 Months'] },
  ]
  const fieldArray = ['emails', 'fields', 'forms']

  const onApply = () => {
    cbOnApply({ savedFieldData, startDateTime, endDateTime, selectedTimeline })
  }
  const handleTimeLineChange = selectedOption => {
    let startDate, endDate
    let today = new Date()
    if (selectedOption !== '0') {
      //Day
      if (selectedOption === '1') {
        startDate = startOfDay(today)
        endDate = endOfDay(today)
      }
      //Week
      if (selectedOption === '2') {
        startDate = startOfWeek(today, { weekStartsOn: 1 })
        endDate = endOfWeek(today, { weekStartsOn: 1 })
      }
      //Month
      if (selectedOption === '3') {
        startDate = startOfMonth(today)
        endDate = endOfMonth(today)
      }
      //Last Month
      if (selectedOption === '4') {
        // startDate=startOfMonth(today);
        startDate = startOfMonth(subMonths(today, 1))
        endDate = endOfMonth(today)
        endDate = subMonths(endDate, 1)
      }
      //Next Month
      if (selectedOption === '5') {
        startDate = startOfMonth(addMonths(today, 1))
        endDate = endOfMonth(startDate, 1)
      }
      //Last Quater
      if (selectedOption === '7') {
        startDate = startOfQuarter(subQuarters(today, 1))
        endDate = endOfQuarter(startDate)
      }
      //Current Quater
      if (selectedOption === '6') {
        startDate = startOfQuarter(today)
        endDate = endOfQuarter(today)
      }
      //Next Quater
      if (selectedOption === '8') {
        startDate = startOfQuarter(addQuarters(today, 1))
        endDate = endOfQuarter(startDate)
      }
      //Previous 6 Months
      if (selectedOption === '9') {
        startDate = startOfMonth(subMonths(today, 6))
        endDate = endOfDay(today)
      }
      //Next 6 Months
      if (selectedOption === '10') {
        startDate = endOfDay(today)
        endDate = endOfMonth(addMonths(today, 6))
      }
      setStartDateTime(startDate)
      setEndDateTime(endDate)
    }
    setSelectedTimeline(Number(selectedOption))
  }

  let report_inline_data = JSON.parse(savedFieldData.report_inline_data)

  let savedField = Object.keys(report_inline_data).map((key, index) => {
    if (key === 'emails') {
      return null
    }
    if (report_inline_data.tag_type.value !== 180 && ['role'].includes(key)) {
      return null
    }
    if (
      report_inline_data.tag_type.value !== 111 &&
      ['product_category', 'product_family', 'product'].includes(key)
    ) {
      return null
    }

    if (fieldArray.includes(key)) {
      let forms = report_inline_data[key]
      return (
        <SimpleGrid
          style={{ direction: 'ltr' }}
          columns={2}
          spacing={1}
          key={index + Math.random() * 7.1}
        >
          <Box>
            <Text textTransform="capitalize" fontWeight="700">
              {key.replace('_', ' ')}
            </Text>
          </Box>
          {forms.map((form, index) => {
            return <Text key={form.label + 'a' + index}>{form.label}</Text>
          })}
        </SimpleGrid>
      )
    }
    return (
      <SimpleGrid style={{ direction: 'ltr' }} columns={2} spacing={1}>
        <Box>
          <Text textTransform="capitalize" fontWeight="700">
            {key.replace('_', ' ')}{' '}
          </Text>
        </Box>
        <Box>
          <Text>
            : {report_inline_data[key] ? report_inline_data[key].label : ''}
          </Text>
        </Box>
      </SimpleGrid>
    )
  })
  return (
    <Box maxH="400px" overflowY="scroll">
      {savedField}
      <Box>
        <Select
          className={`saved-list-select`}
          p="0"
          mt="4"
          value={selectedTimeline}
          onChange={e => handleTimeLineChange(e.target.value)}
        >
          {timeScaleList.map((time, index) => (
            <option key={Math.random() * Date.now() * 3.3} value={time.value}>
              {time.label}
            </option>
          ))}
        </Select>
      </Box>
      <Box mt="4px">
        <HStack spacing={1}>
          <Box w="50%">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className={
                selectedTimeline !== null && selectedTimeline !== 0
                  ? 'date-css disabled'
                  : 'date-css'
              }
              selected={startDateTime}
              onChange={e => setStartDateTime(e)}
              minDate={subMonths(new Date(), 6)}
              maxDate={addMonths(new Date(), 6)}
              required
              disabled={selectedTimeline !== null && selectedTimeline !== 0}
            />
          </Box>
          <Box w="50%">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className={
                selectedTimeline !== null && selectedTimeline !== 0
                  ? 'date-css disabled'
                  : 'date-css'
              }
              selected={endDateTime}
              onChange={e => setEndDateTime(e)}
              minDate={startDateTime}
              maxDate={addMonths(startDateTime, 6)}
              disabled={selectedTimeline !== null && selectedTimeline !== 0}
            />
          </Box>
        </HStack>
        <Box mt="5px" mb="5px" style={{ direction: 'rtl' }}>
          <Button
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DIALOG_BOX,
                action: 'Saved List',
                label: 'Apply',
              })
              onApply()
            }}
            variant="solid"
            bgColor={localStorage.getItem('color')}
            color="white"
            size="sm"
            w="100%"
          >
            {locale['Apply']}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SavedList
