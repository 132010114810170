import * as utils from '../../utils/common.util'
const buildSaveFilterParams = data => {
  let reportInlineJson = {
    forms: data.selectedForm,
    fields: data.selectedField,
    emails: [],
    process: data.selectedProcess,
    tag_type: data.selectedSubApplication,
    timeline: data.selectedTimeline,

    application: data.selectedApplication,
    cluster: data.selectedCluster,
    product_category: data.selectedCategory,
    product_family: data.selectedFamily,
    product: data.selectedProduct,
    date_type: data.selectedDateType,
    role: !!data.selectedRole ? data.selectedRole : null,
    status: data.selectedStatus,
    sub_status: !!data.selectedSubstatus ? data.selectedSubstatus : 0,
    geography: data.selectedBuilding,
    segment: data.selectedSegment,
    team_type: data.selectedWorkforce,
    resource: data.selectedCreator,
    asset_tag_type_1: data.selectedTagType1,
    asset_tag_type_2: data.selectedTagType2,
    asset_tag_type_3: data.selectedTagType3,
    asset_tag_1: data.selectedTag1,
    asset_tag_2: data.selectedTag2,
    asset_tag_3: data.selectedTag3,
  }
  return {
    report_filter_name: data.exportName,
    report_inline_data: JSON.stringify(reportInlineJson),
    report_timescale_id: data.selectedTimeline?.value,
    report_timescale: data.selectedTimeline?.label,
    report_start_datetime: utils.getISTtoUTC(data.selectedStartDate),
    report_end_datetime: utils.getCurrentDateUTCEOD(data.selectedEndDate),
  }
}
const buildAddExportParams = data => {
  const {
    selectedEndDate,
    savedFieldData,
    selectedStartDate,
    startDateTime,
    endDateTime,
  } = data
  let report_inline_data = null
  if (!!savedFieldData) {
    report_inline_data = JSON.parse(savedFieldData.report_inline_data)
  }
  let reportInlineJson = {
    forms: report_inline_data
      ? report_inline_data.forms.map(form => form.value)
      : data.selectedForm.map(form => form.value),
    form_names: report_inline_data
      ? report_inline_data.forms.map(form => form.label)
      : data.selectedForm.map(form => form.label),
    fields: report_inline_data
      ? report_inline_data.fields.map(field => field.value)
      : data.selectedField.map(field => field.value),
    field_names: report_inline_data
      ? report_inline_data.fields.map(field => field.label)
      : data.selectedField.map(field => field.label),
    emails: report_inline_data ? report_inline_data.emails : [], //report_inline_data?report_inline_data.selectedRecievers.split(','),
    processes: report_inline_data
      ? report_inline_data.process.value
      : data.selectedProcess.value,
    process_name: report_inline_data
      ? report_inline_data.process.label
      : data.selectedProcess.label,
    tag_type_id: report_inline_data
      ? report_inline_data.tag_type.value
      : data.selectedSubApplication.value,

    application: report_inline_data
      ? report_inline_data.application.value
      : data.selectedApplication.value,
    cluster: report_inline_data
      ? report_inline_data.cluster.value
      : data.selectedCluster.value,
    product_category: report_inline_data
      ? report_inline_data.product_category.value
      : data.selectedCategory.value,
    product_family: report_inline_data
      ? report_inline_data.product_family.value
      : data.selectedFamily.value,
    product: report_inline_data
      ? report_inline_data.product.value
      : data.selectedProduct.value,
    date_type: report_inline_data
      ? report_inline_data.date_type?.value
      : data.selectedDateType?.value,
    role:
      !!report_inline_data && !!report_inline_data.role
        ? report_inline_data.role.value
        : !!data.selectedRole
        ? data.selectedRole.value
        : 0,
    status: report_inline_data
      ? report_inline_data.status?.value
      : data.selectedStatus?.value,
    sub_status:
      !!report_inline_data && report_inline_data.sub_status
        ? report_inline_data.sub_status.value
        : data && data.selectedSubstatus
        ? data.selectedSubstatus?.value
        : 0,
    geography: report_inline_data
      ? report_inline_data.geography.value
      : data.selectedBuilding.value,
    segment: report_inline_data
      ? report_inline_data.segment.value
      : data.selectedSegment.value,
    team_type: report_inline_data
      ? report_inline_data.team_type.value
      : data.selectedWorkforce.value,
    resource: report_inline_data
      ? report_inline_data.resource.value
      : data.selectedCreator.value,

    application_name: report_inline_data
      ? report_inline_data.application.label
      : data.selectedApplication.label,
    cluster_name: report_inline_data
      ? report_inline_data.cluster.label
      : data.selectedCluster.label,
    product_category_name: report_inline_data
      ? report_inline_data.product_category.label
      : data.selectedCategory.label,
    product_family_name: report_inline_data
      ? report_inline_data.product_family.label
      : data.selectedFamily.label,
    product_name: report_inline_data
      ? report_inline_data.product.label
      : data.selectedProduct.label,
    date_type_name: report_inline_data
      ? report_inline_data.date_type?.label
      : data.selectedDateType?.label,
    role_name:
      !!report_inline_data && !!report_inline_data.role
        ? report_inline_data.role.label
        : !!data.selectedRole
        ? data.selectedRole.label
        : 0,
    status_name: report_inline_data
      ? report_inline_data.status?.label
      : data.selectedStatus?.label,
    sub_status_name: report_inline_data
      ? report_inline_data.sub_status.label
      : data.selectedSubstatus?.label,
    geography_name: report_inline_data
      ? report_inline_data.geography.label
      : data.selectedBuilding.label,
    segment_name: report_inline_data
      ? report_inline_data.segment.label
      : data.selectedSegment.label,
    team_type_name: report_inline_data
      ? report_inline_data.team_type.label
      : data.selectedWorkforce.label,
    resource_name: report_inline_data
      ? report_inline_data.resource.label
      : data.selectedCreator.label,

    filter_asset_tag_1: report_inline_data
      ? report_inline_data.asset_tag_type_1
      : data.selectedTag1.value,
    filter_asset_tag_2: report_inline_data
      ? report_inline_data.asset_tag_type_2
      : data.selectedTag2.value,
    filter_asset_tag_3: report_inline_data
      ? report_inline_data.asset_tag_type_3
      : data.selectedTag3.value,
    filter_asset_tag_type_1: report_inline_data
      ? report_inline_data.asset_tag_1
      : data.selectedTagType1.value,
    filter_asset_tag_type_2: report_inline_data
      ? report_inline_data.asset_tag_2
      : data.selectedTagType2.value,
    filter_asset_tag_type_3: report_inline_data
      ? report_inline_data.asset_tag_3
      : data.selectedTagType3.value,
  }

  let start = selectedStartDate
    ? utils.getISTtoUTC(selectedStartDate)
    : utils.getISTtoUTC(startDateTime)
  let end = selectedEndDate
    ? utils.getCurrentDateUTCEOD(selectedEndDate)
    : utils.getCurrentDateUTCEOD(endDateTime)

  return {
    target_asset_id: 0,
    report_type_id: 9,
    report_inline_data: JSON.stringify(reportInlineJson),
    report_recursive_enabled: 0,
    report_notified_enabled: 1,
    report_recursive_type_id: 0,
    report_access_level_id: 1,
    activity_id: 0,
    report_start_time: null,
    report_end_time: null,
    report_start_datetime: start,
    report_end_datetime: end,
  }
}

export { buildAddExportParams, buildSaveFilterParams }
